import { TableTemplate } from "./tableTemplate";

export class ModuleEntry implements TableTemplate
{
    order: number;
    additionalNotes: string;
    cost: number;
    description: string;

    constructor(order: number) {
        this.description = "";
        this.cost = undefined;
        this.order = order;
        this.additionalNotes = "";
    }
}
<div class="container-grid mt-3">
        <div class="pdf-preview">
            <pdf-preview [quotes]="quotes" [address]="address" #pdfPreview></pdf-preview>
        </div>
        <div class="entries">
            <button class="btn btn-secondary" (click)="pdfPreview.renderPdf()">Generate PDF</button>
            <modal modalTitle="Address" buttonTitle="Enter Address">
                <address-entry [(address)]="address"></address-entry>
            </modal>
            <quote-table [(quotes)]="quotes"></quote-table>
            Custom Date: <input (input)="pdfPreview.date = $event.target.value" />
            <br>
            Sub-quote style: <input type="checkbox" (change)="pdfPreview.isSubQuote = $event.target.checked" />
        </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <ng-content></ng-content>
    </div>
    <div *ngIf="saveTitle" class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="save.emit();modal.close('Save click')">{{ saveTitle }}</button>
    </div>
  </ng-template>
  
  <button class="btn btn-lg btn-outline-primary" (click)="open(content)">{{ buttonTitle }}</button>

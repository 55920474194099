import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModuleEntry } from '../models/moduleEntry';

@Component({
  selector: 'table-drag',
  templateUrl: './table-drag.component.html',
  styleUrls: ['./table-drag.component.scss']
})
export class TableDragComponent implements OnInit {

  @Input() modules: Array<ModuleEntry>;
  @Output() modulesChange = new EventEmitter<Array<ModuleEntry>>();
  @Output() moduleToEdit = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  onDrop(currentIndex: number, newIndex: number) {
    if (newIndex > -1 && newIndex < this.modules.length - 1)
    {
      moveItemInArray(this.modules, currentIndex, newIndex);
      this.modules.forEach((modelEntry, idx) => {
        modelEntry.order = idx + 1;
      });
      this.modulesChange.emit(this.modules);
    }
  }

  editModule(moduleNumber: number)
  {
    this.moduleToEdit.emit(moduleNumber);
  }

  deleteModule(moduleNumber: number)
  {
    this.modules.splice(moduleNumber - 1, 1);
    let orderNumber = 1;
    this.modules.forEach(module => {
      if (module.order != orderNumber)
      {
        module.order = orderNumber;
      }
      orderNumber++;
    });
    this.modulesChange.emit(this.modules);
  }

  onDescriptionUpdate(moduleDesc: string, index: number) {
    if (moduleDesc) {
      this.modules[index].description = moduleDesc.trim();
    }
  }

}

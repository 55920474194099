import { Address } from "./address";
import { ModuleEntry } from "./moduleEntry";

export class Quote
{
    order: number;
    description: string;
    materials: Array<ModuleEntry>;
    labour: Array<ModuleEntry>;
    additionalNotes?: string;

    constructor(order: number, description: string) {
        this.order = order;
        this.description = description;
        this.materials = new Array<ModuleEntry>();
        this.labour = new Array<ModuleEntry>();
        this.additionalNotes = undefined;
    }
}
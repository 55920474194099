export class Address{
    customerName: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    county?: string; 
    postcode: string;
    constructor(){
        this.customerName = "";
        this.addressLine1 = "";
        this.addressLine2 = undefined;
        this.city = "";
        this.county = undefined;
        this.postcode = "";
    }
}
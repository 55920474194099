import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TableDragComponent } from './table-drag/table-drag.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ModuleEntryComponent } from './module-entry/module-entry.component';
import { ModalComponent } from './modal/modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { PdfPreviewComponent } from './pdf-preview/pdf-preview.component';
import { AddressEntryComponent } from './address-entry/address-entry.component';
import { QuoteTableComponent } from './quote-table/quote-table.component';

@NgModule({
  declarations: [
    AppComponent,
    TableDragComponent,
    ModuleEntryComponent,
    ModalComponent,
    PdfPreviewComponent,
    AddressEntryComponent,
    QuoteTableComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    DragDropModule,
    NgbModule,
    CommonModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { rgb, RGB, PDFPageDrawLineOptions, PDFPage } from "pdf-lib";
import { PdfCoordinates } from "./coordinates";

export class PDFLine implements PDFPageDrawLineOptions{
    initialCoords: PdfCoordinates;
    start: PdfCoordinates;
    end: PdfCoordinates;
    length: number;
    colour: RGB;
    opacity: number;
    thickness: number;
    constructor(coords: PdfCoordinates, length: number, weight: number){
        this.length = length;
        this.initialCoords = coords;
        this.thickness = weight;
        this.colour = rgb(0, 0, 0);
        this.opacity = 1;
    }

    public PageSpecificConfig(currentPage: PDFPage): void
    {
        let x = this.initialCoords.x;
        let y = currentPage.getHeight() - this.initialCoords.y;
        this.start = {x, y};
        this.end = {x: x + this.length, y};
    }

    incrementYCoordByHeight(additionalIncrementation?: number){
        this.initialCoords.y += this.thickness + 4 + (additionalIncrementation ?? 0);
    }
}
<div class="table-responsive">
    <table class="table">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Description</th>
                <th scope="col">Cost</th>
                <th scope="col">Additional Notes</th>
                <th scope="col">Options</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let moduleEntry of modules; index as i">
                <th class="col-xs">
                    {{ moduleEntry.order }}
                </th>
                <td contenteditable (blur)="onDescriptionUpdate($event.target.innerHTML, i)" class="col-md">
                    {{ moduleEntry.description }}
                </td>
                <td class="col-md">
                    {{ moduleEntry.cost }}
                </td>
                <td class="col-md">
                    {{ moduleEntry.additionalNotes }}
                </td>
                <td class="col-md">
                    <div (click)="deleteModule(moduleEntry.order)" style="display:inline-block;margin-left:1em;">
                        <ng-container [ngTemplateOutlet]="deleteButtonTmpl"></ng-container>
                    </div>
                    <div *ngIf="modules.length > moduleEntry.order"(click)="onDrop(moduleEntry.order, moduleEntry.order - 1)" style="display:inline-block">
                        ⬇
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #dragHandleTmpl>
	<svg class="bi bi-list handle" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
		xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd"
			d="M2.5 11.5A.5.5 0 013 11h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 7h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 3h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
			clip-rule="evenodd" />
	</svg>
</ng-template>
<ng-template #editButtonTmpl>
	<svg class="bi bi-list edit-button" width="1em" height="1em" viewBox="0 0 528.899 528.899"
		xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd"
			d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981
            c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611
            C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069
            L27.473,390.597L0.3,512.69z"
			clip-rule="evenodd" />
	</svg>
</ng-template>
<ng-template #deleteButtonTmpl>
	<svg class="bi bi-list delete-button" width="1em" height="1em" viewBox="0 0 459 459"
		xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd"
			d="M76.5,408c0,28.05,22.95,51,51,51h204c28.05,0,51-22.95,51-51V102h-306V408z M408,25.5h-89.25L293.25,0h-127.5l-25.5,25.5
			H51v51h357V25.5z"
			clip-rule="evenodd" />
	</svg>
</ng-template>
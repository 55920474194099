<form class="row g-3 mb-3" [formGroup]="moduleForm" (ngSubmit)="submitModuleEntry();focus.focus()">
    <div class="col-sm-6">
        <label class="form-label">
            Description:
        </label>
        <input class="form-control" type="text" formControlName="description" #focus>
    </div>
    <div class="col-sm-6">
        <label class="form-label">
            Cost:
        </label>
        <input class="form-control" type="number" formControlName="cost">
    </div>
    <div class="col-sm-12">
        <label class="form-label">
            Additional Notes(optional):
        </label>
        <input class="form-control" type="string" formControlName="additionalNotes">
    </div>
    <div class="col-12">
    <button type="submit" class="btn btn-primary">Add to quote</button>
    </div>
</form>

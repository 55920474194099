import { PDFFont, PDFPage, RGB, StandardFonts } from 'pdf-lib';
import { PdfCoordinates } from './coordinates';

export class PDFText{
    x: number;
    y: number;
    size: number;
    standardFont: StandardFonts;
    initialCoords: PdfCoordinates;
    font: PDFFont;
    color: RGB;
    text: string;

    public get width(): number {
      return this.font.widthOfTextAtSize(this.text, this.size);
    }

    constructor(text: string, coordinates: PdfCoordinates, fontSize: number, font: StandardFonts, color: RGB) {
        this.initialCoords = coordinates;
        this.standardFont = font;
        this.size = fontSize;
        this.color = color;
        this.text = text;
    }

    public PageSpecificConfig(fonts: Map<StandardFonts, PDFFont>, currentPage: PDFPage): void
    {
        this.font = fonts.get(this.standardFont);
        this.x = this.initialCoords.x;
        this.y = currentPage.getHeight() - this.initialCoords.y;
    }
}

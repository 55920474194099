import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Target } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModuleEntry } from '../models/moduleEntry';
import { Quote } from '../models/quote';

@Component({
  selector: 'quote-table',
  templateUrl: './quote-table.component.html',
  styleUrls: ['./quote-table.component.scss']
})
export class QuoteTableComponent implements OnInit {

  @Input() quotes: Array<Quote>;
  @Output() quotesChange = new EventEmitter<Array<Quote>>();

  quoteInformation = new FormGroup({
    description: new FormControl(""),
    additionalNotes: new FormControl("")
  });

  newMaterial: ModuleEntry;
  newLabourEntry: ModuleEntry;
  quoteToEdit: number;
  quoteButtonName: string;

  constructor() { 
    this.quoteToEdit = undefined;
    this.newMaterial = new ModuleEntry(1);
    this.newLabourEntry = new ModuleEntry(1);
    this.quoteButtonName = "Create new quote";
  }

  ngOnInit(): void {
  }

  public addMaterial(quoteNumber: number, newMaterial: ModuleEntry): void {
    console.log(quoteNumber);
    newMaterial.order = this.quotes[quoteNumber - 1].materials.length + 1;
    console.log(`New material order ${newMaterial.order}`);

    this.quotes[quoteNumber - 1].materials.push(newMaterial);

    this.quotesChange.emit(this.quotes);
  }

  public addLabour(quoteNumber: number, newLabourEntry: ModuleEntry): void {
    newLabourEntry.order = this.quotes[quoteNumber - 1].labour.length + 1;

    this.quotes[quoteNumber - 1].labour.push(newLabourEntry);

    this.quotesChange.emit(this.quotes);
  }

  onDrop(currentIndex: number, newIndex: number) {
    if (newIndex > -1 && newIndex < this.quotes.length - 1)
    {
      moveItemInArray(this.quotes, currentIndex, newIndex);
      this.quotes.forEach((quote, idx) => {
        quote.order = idx + 1;
      });
      this.quotesChange.emit(this.quotes);
    }
  }

  submitQuoteInformation(){
    if (this.quoteToEdit != undefined)
    {
      this.quotes[this.quoteToEdit].description = this.quoteInformation.value.description;
      this.quotes[this.quoteToEdit].additionalNotes = this.quoteInformation.value.additionalNotes;
      this.quoteButtonName = "Create new quote";
      console.log(`Updated quote ${this.quoteToEdit}`);
      this.quoteToEdit = undefined;
    }
    else
    {
      let newQuote = new Quote(this.quotes.length + 1, this.quoteInformation.value.description);
      newQuote.additionalNotes = this.quoteInformation.value.additionalNotes;
      this.quotes.push(newQuote);
      console.log(`Pushed a new quote to the quotes array. Now contains ${this.quotes.length}`);
    }
    this.quoteInformation.setValue({description: "", additionalNotes: ""});
    console.log("Set quote inputs back to empty");
    this.quotesChange.emit(this.quotes);
    console.log("Quote-table component emitted quotes");
  }

  editQuote(quoteNumber: number)
  {
    this.quoteToEdit = quoteNumber - 1;
    this.quoteInformation.setValue({description: this.quotes[this.quoteToEdit].description, additionalNotes: this.quotes[this.quoteToEdit].additionalNotes});
    this.quoteButtonName = "Update quote information";
    console.log(`Currently editing quote ${this.quoteToEdit}`);
  }

  onQuoteDescUpdate(quoteDesc: string, index: number) {
    if (quoteDesc) {
      this.quotes[index].description = quoteDesc.trim();
    }
  }

  
  deleteQuote(quoteNumber: number)
  {
    this.quotes.splice(quoteNumber - 1, 1);
    let orderNumber = 1;
    this.quotes.forEach(quote => {
      if (quote.order != orderNumber)
      {
        quote.order = orderNumber;
      }
      orderNumber++;
    });
    this.quotesChange.emit(this.quotes);
  }

  editMaterial(materialNumber: number)
  {
    alert(materialNumber);
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { values } from 'pdf-lib';
import { ModuleEntry } from '../models/moduleEntry';

@Component({
  selector: 'module-entry',
  templateUrl: './module-entry.component.html',
  styleUrls: ['./module-entry.component.scss']
})
export class ModuleEntryComponent {

  moduleForm = new FormGroup({
    description: new FormControl(""),
    cost: new FormControl(""),
    additionalNotes: new FormControl("")
  });

  @Output() moduleChange = new EventEmitter<ModuleEntry>();

  constructor() { }

  submitModuleEntry(): void {
    let module: ModuleEntry = {
      description: this.moduleForm.value.description ?? "",
      cost: this.moduleForm.value.cost,
      additionalNotes: this.moduleForm.value.additionalNotes,
      order: 0
    }
    console.log(`Input added to temporary module in module-entry component: ${module}`);
    this.clearInputFields();
    this.moduleChange.emit(module);
  }

  clearInputFields(): void {
    this.moduleForm.reset();
    console.log("Cleared moduleForm input fields");
  }

}

<form class="row g-3 mb-3" [formGroup]="addressForm" (ngSubmit)="submitAddressEntry()">
    <div class="col-sm-12">
        <label class="form-label">
            Customer Name:
        </label>
        <input class="form-control" type="text" formControlName="customerName">
    </div>
    <div class="col-sm-6">
        <label class="form-label">
            Address Line 1:
        </label>
        <input class="form-control" type="text" formControlName="addressLine1">
    </div>
    <div class="col-sm-6">
        <label class="form-label">
            Address Line 2 (optional):
        </label>
        <input class="form-control" type="text" formControlName="addressLine2">
    </div>
    <div class="col-sm-12">
        <label class="form-label">
            City:
        </label>
        <input class="form-control" type="text" formControlName="city">
    </div>
    <div class="col-sm-6">
        <label class="form-label">
            County (optional):
        </label>
        <input class="form-control" type="text" formControlName="county">
    </div>
    <div class="col-sm-6">
        <label class="form-label">
            Postcode:
        </label>
        <input class="form-control" type="text" formControlName="postcode">
    </div>
    <div class="col-12">
    <button type="submit" class="btn btn-primary">Add to quote</button>
    </div>
</form>

import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { rgb, StandardFonts } from 'pdf-lib';
import { environment } from 'src/environments/environment';
import { Address } from '../models/address';
import { ModuleEntry } from '../models/moduleEntry';
import { Quote } from '../models/quote';
import { PdfCoordinates } from '../pdfModels/coordinates';
import { PDFFile } from '../pdfModels/pdfFile';
import { PDFLine } from '../pdfModels/pdfLine';
import { PDFText } from '../pdfModels/textOptions';

@Component({
  selector: 'pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss']
})
export class PdfPreviewComponent implements OnInit {

  @Input() quotes: Array<Quote>;
  @Input() address: Address;
  @Input() date: string;
  @Input() isSubQuote: boolean;

  iFrameSrc: SafeUrl;
  iFrameWidth: number;
  iFrameHeight: number;
  pdfDoc: PDFFile;
  currentQuote: Quote;
  coordinates: PdfCoordinates;
  pdfText: PDFText;

  constructor(private domSanitiser: DomSanitizer) {
    this.coordinates = { x: 90, y: 80 };
    this.pdfText = new PDFText("", this.coordinates, 0, StandardFonts.Helvetica, rgb(0, 0, 0));
  }

  ngOnInit() {
    this.iFrameSrc = this.domSanitiser.bypassSecurityTrustResourceUrl("");
  }

  async renderPdf() {
    if (this.quotes) {
      this.pdfDoc = await new PDFFile().initialisePdf();

      this.pdfText.color = rgb(0, 0, 0);

      if (this.isSubQuote) {
        this.pdfDoc.addNewPage();
        this.addHeaderTemplate();
        this.coordinates.y += 20;
        this.addAddress();
        this.quotes.forEach(quote => {
          this.currentQuote = quote;
          this.coordinates.y += 20;
          this.addQuoteDescription(quote, null, true);
          this.addQuoteAdditionalNotes(quote);
          this.coordinates.y += 20;
          this.addMaterials();
          this.coordinates.y += 20;
          this.addLabour();
        });
        this.addFooterTemplate();
      }
      else {
        this.quotes.forEach((quote) => {
          this.pdfDoc.addNewPage();
          this.currentQuote = quote;
          this.addHeaderTemplate();
          this.coordinates.y += 20;
          this.addAddress();
          this.coordinates.y += 20;
          this.addQuoteDescription(quote);
          this.addQuoteAdditionalNotes(quote);
          this.coordinates.y += 20;
          this.addMaterials();
          this.coordinates.y += 20;
          this.addLabour();
          this.addFooterTemplate();
        })
      }

      this.addMandatoryNotes();

      this.pdfText.color = rgb(0.5, 0.5, 0.5);

      if (this.isSubQuote) {
        this.pdfDoc.addNewPage();
        this.addHeaderTemplate();
        this.coordinates.y += 20;
        this.addAddress();
        this.quotes.forEach((quote) => {
          this.currentQuote = quote;
          this.coordinates.y += 20;
          this.addQuoteDescription(quote, "Copy Quotation", true);
          this.addQuoteAdditionalNotes(quote);
          this.coordinates.y += 20;
          this.addMaterials();
          this.coordinates.y += 20;
          this.addLabour();
        })
        this.addCCSign();
        this.addFooterTemplate();
      }
      else {
        this.quotes.forEach((quote) => {
          this.pdfDoc.addNewPage();
          this.currentQuote = quote;
          this.addHeaderTemplate();
          this.coordinates.y += 20;
          this.addAddress();
          this.coordinates.y += 20;
          this.addQuoteDescription(quote, "Copy Quotation");
          this.addQuoteAdditionalNotes(quote);
          this.coordinates.y += 20;
          this.addMaterials();
          this.coordinates.y += 20;
          this.addLabour();
          this.addCCSign();
          this.addFooterTemplate();
        })
      }

      await this.renderDoc();
    }
  }

  private async renderDoc() {
    const blob = new Blob([await this.pdfDoc.getPdfDocumentBytes()], { type: 'application/pdf' });
    this.iFrameSrc = this.domSanitiser.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    let pageDimensions = this.pdfDoc.getPageDimensions();
    this.iFrameHeight = pageDimensions.height;
    this.iFrameWidth = pageDimensions.width;
  }

  private addQuoteDescription(quote: Quote, nameForQuote?: string, subQuote: boolean = false) {
    this.coordinates.x = 90;
    if (!subQuote || quote.order == 1) {
      if (nameForQuote) {
        this.pdfText.text = nameForQuote;
      }
      else {
        this.pdfText.text = "Quotation";
      }
    }

    if (!subQuote && this.quotes.length > 1) {
      this.pdfText.text += ` - Page ${quote.order}`;
    }

    if (!subQuote || quote.order == 1) {
      this.pdfText.size = 18;
      this.addTextAndIncrement(6);
    }


    this.pdfText.text = quote.description;
    this.pdfText.size = 14;
    this.addTextAndIncrement();
  }

  private addQuoteAdditionalNotes(quote: Quote) {
    if (quote.additionalNotes) {
      this.pdfText.text = `(${quote.additionalNotes})`;
      this.pdfText.size = 12;
      this.addTextAndIncrement();
    }
  }

  public addHeaderTemplate() {
    this.coordinates.x = 90;
    this.coordinates.y = 80;
    this.pdfText.text = "ULLCOMBE JOINERY";
    this.pdfText.size = 20;
    this.addTextAndIncrement(12);

    this.pdfText.text = "Mark Farmer";
    this.pdfText.size = 28;
    this.addTextAndIncrement(-6);

    this.pdfText.text = "for high quality joinery and carpentry";
    this.pdfText.size = 14;
    this.addTextAndIncrement();

    this.addDivider();
  }

  private addCCSign() {
    this.coordinates.y = this.pdfDoc.getPageDimensions().height - 140;
    this.coordinates.x = 90;
    this.pdfText.text = "Signed................................ Print name.................................. Date....................";
    this.pdfText.size = 12;
    this.addTextAndIncrement(4);
  }

  public addFooterTemplate() {
    this.coordinates.y = this.pdfDoc.getPageDimensions().height - 120;

    this.addDivider(20);

    this.coordinates.x = 90;
    this.pdfText.text = "To contact Mark";
    this.pdfText.size = 12;
    this.addTextAndIncrement(4);

    this.pdfText.text = "Telephone: Honiton (01404) 861421   Email: markfarmerullcombe@gmail.com";
    this.addTextAndIncrement(4);

    this.pdfText.text = "Ullcombe Joinery. Ullcombe Lane. Upottery. Devon EX14 9PA ";
    this.addTextAndIncrement();
  }

  private addMandatoryNotes() {
    this.pdfDoc.addNewPage();
    this.coordinates.x = 90;
    this.coordinates.y = 80;
    this.pdfText.size = 12;
    this.pdfText.text = `PLEASE READ THESE NOTES CAREFULLY BEFORE SIGNING AND RETURNING THE COPY QUOTATION${this.quotes.length > 1 ? "S" : ""}`;
    this.addTextAndIncrement(4);

    this.addDivider(20);

    this.pdfText.text = "The quotation does not include the cost of preserving or painting.";
    this.addTextAndIncrement(12);

    this.pdfText.text = "To minimise the normal movement of wood, all items need to be treated and stored appropriately after delivery.";
    this.addTextAndIncrement(12);

    this.pdfText.text = "The cost of wood and materials will need to be paid for in advance, the cost of making within seven days of delivery, and the cost of fitting within seven days of job completion.";
    this.addTextAndIncrement(12);

    this.pdfText.text = "Prices may vary slightly due to fluctuations in cost of wood etc.";
    this.addTextAndIncrement(12);

    this.addDivider();
  }

  public addDate(size: number) {
    let currentDate = this.date ? this.date : new DatePipe('en_GB').transform(Date.now(), 'd MMMM yyyy');
    this.coordinates.x = this.pdfDoc.getPageDimensions().width - this.pdfDoc.lengthWithOptions(currentDate, StandardFonts.Helvetica, size) - 94;
    this.pdfText.text = currentDate;
    this.pdfText.size = size;
    this.addTextAndIncrement();
  }

  private addTextAndIncrement(additionalIncrementation?: number): number[] {
    return this.pdfDoc.addParagraph(this.pdfText, additionalIncrementation);
  }

  public addAddress() {
    if (this.address) {
      this.coordinates.x = 90;

      let additionalLineSpaceIncrement = 3;

      this.pdfText.text = this.address.customerName;
      this.pdfText.size = 14;
      this.addTextAndIncrement(additionalLineSpaceIncrement);

      this.pdfText.text = this.address.addressLine1;
      this.addTextAndIncrement(additionalLineSpaceIncrement);

      if (this.address.addressLine2) {
        this.pdfText.text = this.address.addressLine2;
        this.addTextAndIncrement(additionalLineSpaceIncrement);

      }

      this.pdfText.text = this.address.city;
      this.addTextAndIncrement(additionalLineSpaceIncrement);


      if (this.address.county) {
        this.pdfText.text = this.address.county;
        this.addTextAndIncrement(additionalLineSpaceIncrement);

      }

      this.pdfText.text = this.address.postcode;
      this.pdfDoc.addTextToPage(this.pdfText);

      this.addDate(this.pdfText.size);

      this.addDivider();
    }
  }

  private addDivider(additionalIncrementation?: number) {
    this.coordinates.x = 90;
    let length = this.pdfDoc.getPageDimensions().width - this.coordinates.x - 90;
    let pdfLine = new PDFLine(this.coordinates, length, 2);
    this.pdfDoc.addSeparatorToPage(pdfLine);
    pdfLine.incrementYCoordByHeight(additionalIncrementation);
  }

  public addMaterials() {
    if (this.currentQuote.materials) {
      this.coordinates.x = 90;
      this.pdfText.text = "Materials";
      this.pdfText.size = 16;
      this.addTextAndIncrement();

      for (let material of this.currentQuote.materials) {
        this.addNewModule(material);
      }
    }
  }
  public addLabour() {
    if (this.currentQuote.labour) {
      this.coordinates.x = 90;
      this.pdfText.text = "Labour";
      this.pdfText.size = 16;
      this.addTextAndIncrement()

      for (let labourEntry of this.currentQuote.labour) {
        this.addNewModule(labourEntry);
      }
    }
  }

  public addNewModule(module: ModuleEntry) {
    this.coordinates.x = 90;

    this.pdfText.text = module.description;
    this.pdfText.size = 12;
    // this.pdfDoc.addTextToPage(this.pdfText);
    this.pdfDoc.maxWidth = environment.pageBounds.components.width;
    var yCoord = this.addTextAndIncrement();

    let isToFit: boolean = module.description.toUpperCase().trim() == "TO FIT";


    if (module.cost) {
      this.pdfText.text = `£${this.transformToCurrency(module.cost)} ${isToFit ? "(per hour)" : ""}`;
    }
    else {
      this.pdfText.text = "";
    }
    this.coordinates.y = yCoord[1];
    this.coordinates.x = this.pdfDoc.getPageDimensions().width - this.pdfDoc.lengthWithOptions(this.pdfText.text, StandardFonts.Helvetica, 12) - 90;
    this.addTextAndIncrement(4);

    if (module.additionalNotes) {
      this.pdfText.text = `(${module.additionalNotes})`
      this.coordinates.x = this.pdfDoc.getPageDimensions().width - this.pdfDoc.lengthWithOptions(this.pdfText.text, StandardFonts.Helvetica, 12) - 90;
      this.addTextAndIncrement(6);
    }
    this.pdfDoc.maxWidth = environment.pageBounds.maxWidth;
  }

  private transformToCurrency(amount: number): string {
    return amount.toFixed(2);
  }
}

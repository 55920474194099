import { PDFDocument, PDFFont, PDFPage, StandardFonts } from 'pdf-lib';
import { environment } from 'src/environments/environment';
import { PageDimensions } from './pageDimensions';
import { PDFLine } from './pdfLine';
import { PDFText } from './textOptions';

export class PDFFile{
    private pdfDoc: PDFDocument;
    private pdfFonts: Map<StandardFonts, PDFFont>;
    public maxWidth: number;

    constructor()
    {
        this.pdfFonts = new Map<StandardFonts, PDFFont>();
        this.maxWidth = environment.pageBounds.maxWidth;
    }

    public async initialisePdf(fileByte?: Uint8Array): Promise<PDFFile>
    {
        if (fileByte)
        {
            this.pdfDoc = await PDFDocument.load(fileByte);
        }
        else
        {
            this.pdfDoc = await PDFDocument.create();
        }
        const helvetica = await this.pdfDoc.embedFont(StandardFonts.Helvetica);
        this.pdfFonts.set(StandardFonts.Helvetica, helvetica);
        return this;
    }

    public lengthWithOptions(text: string, font: StandardFonts, size: number): number
    {
        return this.pdfFonts.get(font).widthOfTextAtSize(text, size + 1);
    }

    public heightWithOptions(font: StandardFonts, size: number): number
    {
        return this.pdfFonts.get(font).heightAtSize(size);
    }

    public async embedFont(font: StandardFonts): Promise<PDFFile>
    {
        await this.pdfDoc.embedFont(font);
        return this;
    }

    public addNewPage(pageNumber?: number): PDFFile
    {
        let newPdfPage: PDFPage;
        if (pageNumber)
        {
            newPdfPage = this.pdfDoc.insertPage(pageNumber);
        }
        else
        {
            newPdfPage = this.pdfDoc.addPage();
        }

        return this;
    }

    public getPageDimensions(index?: number): PageDimensions
    {
        if (!index)
        {
            index = this.pdfDoc.getPageCount();
        }
        index -= 1;
        return this.pdfDoc.getPage(index).getSize();
    }

    public addTextToPage(text: PDFText, pageNumber?: number): PDFFile
    {
        pageNumber = (pageNumber ?? this.pdfDoc.getPageCount()) - 1;
        let page = this.pdfDoc.getPage(pageNumber)
        text.PageSpecificConfig(this.pdfFonts, page);
        page.drawText(text.text, text);
        return this;
    }

    public addParagraph(text: PDFText, additionalIncrementation?: number): number[]
    {
        let pageNumber = (this.pdfDoc.getPageCount()) - 1;
        let page = this.pdfDoc.getPage(pageNumber)

        text.PageSpecificConfig(this.pdfFonts, page);

        var startingY = text.initialCoords.y.valueOf();
        var endingY = text.initialCoords.y.valueOf();

        if (text.width > this.maxWidth) {
          var wordsInText = text.text.split(' ');
          var linesOfText = wordsInText.reduce((acc, cur) => {
            text.text = `${acc[0][(acc[1] as number)]} ${cur}`;
            if (text.width > this.maxWidth)
            {
              (acc[0] as string[]).push(cur);
              (acc[1] as number)++;
              return acc;
            }
            (acc[0][(acc[1] as number)] as string) = text.text;
            return acc;
          }, [[""], 0]);

          (linesOfText[0] as string[]).forEach((l, i) => {
            text.PageSpecificConfig(this.pdfFonts, page);
            page.drawText(l.trim(), text);
            var accountForTextSize = Math.round(text.size * 0.25);

            if (i + 1 == (linesOfText[0] as string[]).length)
            {
              endingY = text.initialCoords.y.valueOf();
              this.incrementYCoordByHeight(text, additionalIncrementation);
            }
            else {
              this.incrementYCoordByHeight(text, accountForTextSize);
            }
          });
          return [startingY, endingY];
        }
        page.drawText(text.text, text);
        endingY = text.initialCoords.y.valueOf();
        this.incrementYCoordByHeight(text, additionalIncrementation);
        return [startingY, endingY];
    }

    public addSeparatorToPage(pdfLine: PDFLine, pageNumber?: number): PDFFile
    {
        pageNumber = (pageNumber ?? this.pdfDoc.getPageCount()) - 1;
        let page = this.pdfDoc.getPage(pageNumber)
        pdfLine.PageSpecificConfig(page);
        page.drawLine(pdfLine);
        return this;
    }

    public incrementYCoordByHeight(text: PDFText, additionalIncrementation?: number)
    {
        text.initialCoords.y += this.heightWithOptions(text.standardFont, text.size) + (additionalIncrementation ?? 0);
    }

    public async getPdfDocumentBytes(): Promise<Uint8Array> {
        let serialised = await this.pdfDoc.save();
        return serialised
    }
}

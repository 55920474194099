import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Address } from '../models/address';

@Component({
  selector: 'address-entry',
  templateUrl: './address-entry.component.html',
  styleUrls: ['./address-entry.component.scss']
})
export class AddressEntryComponent implements OnInit, OnChanges {

  addressForm = new FormGroup({
    customerName: new FormControl(""),
    addressLine1: new FormControl(""),
    addressLine2: new FormControl(""),
    city: new FormControl(""),
    county: new FormControl(""),
    postcode: new FormControl("") 
  });

  @Input() address: Address;
  @Output() addressChange = new EventEmitter<Address>();

  constructor() { }

  ngOnInit(): void {
    this.setFormGroupValues();
  }

  ngOnChanges(){
    this.setFormGroupValues();
  }

  private setFormGroupValues(){
    if (this.address)
    {
      this.addressForm.setValue({
        customerName: this.address.customerName ?? "",
        addressLine1: this.address.addressLine1 ?? "",
        addressLine2: this.address.addressLine2 ?? "",
        city: this.address.city ?? "",
        county: this.address.county ?? "",
        postcode: this.address.postcode ?? ""
      });
    }
  }

  submitAddressEntry(): void {
    this.address.customerName = this.addressForm.value.customerName;
    this.address.addressLine1 = this.addressForm.value.addressLine1;
    this.address.addressLine2 = this.addressForm.value.addressLine2;
    this.address.city = this.addressForm.value.city;
    this.address.county = this.addressForm.value.county;
    this.address.postcode = this.addressForm.value.postcode;
    this.addressChange.emit(this.address);
  }

}

import { Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModuleEntry } from './models/moduleEntry';
import { Address } from './models/address';
import { Quote } from './models/quote';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Quote-Builder';
  pdfDoc: Uint8Array;
  public iFrameSrc: SafeUrl;
  iFrameWidth = 30;
  iFrameHeight = 30;
  quotes: Array<Quote>;
  address: Address;

  newMaterial: ModuleEntry;

  newLabourEntry: ModuleEntry;

  currentPdf: number;
  constructor(private domSanitiser: DomSanitizer)
  {
    this.iFrameSrc = this.domSanitiser.bypassSecurityTrustResourceUrl("");

    this.newMaterial = new ModuleEntry(1);

    this.newLabourEntry = new ModuleEntry(1);

    this.quotes = new Array<Quote>();
    this.address = new Address();
  }

  test()
  {
    alert("Hello");
  }

}
